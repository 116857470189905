.mycomponent {
    background-color: #0E4626;
    width: 100%;
    height: 100vh;
    /* Add any other styles you want here */
}

.centerbox {
    width: 48.125rem;
    height: 34.625rem;
    background: #FFFFFF;
    border-radius: 0.3125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
}

.rightbox {
    width: 29.6875rem;
    height: 34.625rem;
    background-color: #F5F5F5;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rightboxtitle {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    /* convert 30px to rem */
    line-height: 1.1333;
    color: #174F00;
    margin: 2.5rem 1.25rem 0 1.25rem;
}

.right_box__form {
    display: flex;
    flex-direction: column;
    margin: 2.25rem 1.25rem 1.25rem 1.25rem;
    align-items: center;
}

.right_box__form>*+* {
    margin-top: 1.8125rem;
    /* convert 58px to rem */
}


.username_input {
    width: 25rem;
    margin-bottom: 1.5rem !important;
}

.password_input {
    width: 25rem;
    margin-bottom: 1.5rem;
}

.right_box__login_btn {
    margin-top: 3.5rem;
    /* convert 56px to rem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    /* convert 10px to rem */
    padding: 1.25rem 4.375rem;
    /* convert 10px and 70px to rem */
    width: 11.625rem;
    /* convert 186px to rem */
    height: 2.3125rem;
    /* convert 37px to rem */
    background-color: #0E4626;
    border-radius: 0.3125rem;


    text-transform: uppercase;

    letter-spacing: 0.1rem;
    cursor: pointer;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9375rem;
    /* convert 15px to rem */
    line-height: 1.0625rem;
    /* convert 17px to rem */
    color: #FFFFFF;
    white-space: nowrap;
}

.logo {
    width: 9.375rem;
    /* convert 150px to rem */
    height: 9.625rem;
    /* convert 154px to rem */


}

.leftBox {
    width: 18.75rem;
    /* 750px / 16 = 46.875rem */
    height: 34.625rem;
    /* 874px / 16 = 54.625rem */
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    align-items: center;
    background-image: url('../../assets/parklist.png');
}

.title {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-top: 20px;

    color: #FFFFFF;
}

.rightBox__error {
    color: red;
    margin-bottom: 1rem;
}

.parklist_box {
    width: 25rem;
    height: 18.94rem;

    border: 1px solid rgba(14, 70, 38, 0.5);
    border-radius: 5px;
    margin-top: 2.5rem;

}

.parkname {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */


    color: #000000;
}

.city {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;

    color: rgba(0, 0, 0, 0.6);

}

.street {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    margin-left: 6px;
    color: rgba(0, 0, 0, 0.6);
}

.add_park_button {
    width: 10.81rem;
    height: 2.31rem;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #0E4626;
    border-color: #0E4626;
}

.next_button {
    width: 10.81rem;
    height: 2.31rem;
    background: #0E4626 !important;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.add_Park_modal {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 34px;
    /* identical to box height */
    color: #0E4626;

}

.add_Park_modal_sub {

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #000000;

}

.add_park_modal_button_save {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 70px;
    gap: 10px;


    width: 176px;
    height: 37px;


    background: #0E4626 !important;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;


    color: #FFFFFF !important;
}

.add_park_modal_button_cancel {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 70px;
    gap: 10px;


    width: 176px;
    height: 37px;


    background: #DF2629 !important;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;


    color: #FFFFFF !important;
}

.add_park_modal_button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

