.svg1 {
    box-sizing: border-box;

    position: absolute;
    left: 1.8rem;
    top: 20rem;
width: 28.31%;
height: 52.76%;
}

.svg2 {
    position: absolute;
    left: 0px;
    top: 1.5rem;
}

.svg3 {
    position: absolute;
    left: 57.19%;
    top: 74.34%;
    width: 42.96%;
    height: 25.78%;
}