.container{
    background: #0E4626;
   
}
.left{
    
}
.title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 122.5%;
    color: #FFFFFF;
}
.wraper{
    margin-top: 13px;
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}
.imagewraper img{
   width:100%
}
.right_title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 160.52%;
    /* or 64px */

    text-align: center;
    color: #FFFFFF;

}
.rightwrapper{
    margin-top: 80px;

}
.gameBox{
    background: #FFFFFF;
    border-radius: 10px;
    width:160px;
    height: 160px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
}
.gameBoxPassive{
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width:160px;
    height: 160px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    color: #FFFFFF;

}
a { color: inherit; } 
.gameBoxWrapper{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}
.gameBox h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    text-align: center;
    color: #000000;

}
.box{
    background: #FFFFFF;
    border-radius: 10px;
    width:160px;
    height: 160px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
}
.formWrapper{
    margin-top: 30px;
    margin-left: 30px;
    width: 400px;
}
.dialogTitle{
    font-family: 'Roboto';
font-style: normal;
font-size: 33px;
line-height: 34px;
color: #0E4626;

}
.dialogContent p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #0E4626;
    
}
.dialogNumber{
    color: #257fe6;
    font-size: 25px;
}
