.label{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160.52%;
    color: #FFFFFF;
    margin-right: 50px;
    text-align: left;
}
.reqButton{
padding: 10px 20px;
background: #FFFFFF;
border-radius: 10px
}
.formRow{
    display: flex;
    justify-content:space-between;
    margin-top: 18px;
}
.rightBox{
    width:250px;
    text-align: left;
}
.price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 160.52%;
    color: #FFFFFF;

}
.validation{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160.52%;
  color: #c30606;
  text-align: left;
}
/* input[type='date']:before {
    content: attr(placeholder)
  }
  input[type='time']:before {
    content: attr(placeholder)
  }  
input::-webkit-datetime-edit{   display: none; }
input{
    width:200px
}
input[type=date]::-webkit-calendar-picker-indicator {
    margin-left: 70px;
  }
  input[type=time]::-webkit-calendar-picker-indicator {
    margin-left: 70px;
  }   */