.container {
  background-color: #0e4626;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

}

.logo {
  width: 12.5rem;
  height: 12.8rem;
  margin-top: 7.5rem;
  z-index: 100;
}

.title {
  font-family: "Georgia", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.13rem;
  margin: 1.25rem 0 0 0;
  color: #fff;
}

.subtitle {
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.88rem;
  line-height: 2.12rem;
  margin: 3.13rem 0 1.25rem 0;
  color: #fff;
}

.text {
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.67rem;
  line-height: 1.9rem;
  margin: 0 0 3.13rem 0;
  color: #fff;
}

.button {
  padding: 1.25rem 5.63rem;
  gap: .63rem;
  width: 17.56rem;
  height: 4.63rem;
  background: #ffffff;
  border-radius: 0.5rem;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.88rem;
  line-height: 2.13rem;
  white-space: nowrap;
  color: #0E4626;
}

