.title{
    font-family: 'Roboto';
    color: #FFFFFF;
    width: 200px;
    font-size: 46px;
    font-weight: 400;
    margin-left: 85px;
    line-height: 80px;
    margin-top: 20px;
}
.logo{
    margin-top: 160px;
    text-align: center;
}
.left{
  
    background: #0E4626;
    padding-left: 0; 
    padding-right: 0;
}
.right{
    padding-left: 0; 
    padding-right: 0;   
}
.right img{
    margin:0;
    width:100%;
    max-height:50vh;
    transform:scale(1, 1);
    transition: 1s;
    transform-origin: 50% 50%;
}

.right img:hover {
        transform:scale(1.2, 1.2);
        width: 100%;
}
.imageBox{
    position: relative;
    overflow: hidden;
}
.imageText{
    position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  height:100%;
  padding: 20px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.imageText h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 160.52%;
    color: #FFFFFF;
}
.details{
    position:absolute;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, .8);
}
.dialogTitle{
    font-family: 'Roboto';
font-style: normal;
font-size: 33px;
line-height: 34px;
color: #0E4626;

}
.dialogContent p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #0E4626;
    
}
.dialogNumber{
    color: #257fe6;
    font-size: 25px;
}
