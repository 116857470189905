.container{
    width:100%;
    height: 100vh;
    text-align: center;
    padding-top: 160px;
    background: url('../../assets/thankyou.png') no-repeat center center fixed;
    background-size: cover;
}
.titleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #FFFFFF;

}
.subTitleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    color: #FFFFFF;
}
.logo{
    margin-bottom: 10px;
}
.linkToHome{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #0E4626;
    margin-top: 30px;

}
.title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 10px;
}
.rightwrapper{
    width: 340px;
  }
.rightwrapperContainer{
    margin-top: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
} 
  .label{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160.52%;
      color: #FFFFFF;
    
  }
  .formRow{
    display: flex;
    justify-content:space-between;
    margin-top: 18px;
}
.rightBox{
    width:150px;
    text-align: left;
}
.price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 160.52%;
    color: #FFFFFF;

}

