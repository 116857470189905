.dialogTitle{
    font-family: 'Roboto';
font-style: normal;
font-size: 33px;
line-height: 34px;
color: #0E4626;

}
.dialogContent p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #0E4626;
    
}
.dialogNumber{
    color: #257fe6;
    font-size: 25px;
}
