.container{
    width:100%;
    height: 100vh;
    text-align: center;
    padding-top: 15vh;
    background: #0E4626;
    transition-property:padding-top;
    transition-duration: 1s;
}
.titleContainer{
    margin-top: 40px;
    transition-property:margin-top,font-size;
    transition-duration: 1s;
    margin-bottom: 30px;
   
}
.titleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #FFFFFF;
   

}
.logoImage{
    width:40vh;
    transition-property:width;
    transition-duration: 1s;
}
.linkToHome{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #ffffff;
    margin-top: 30px;
    transition-property:display;
    transition-duration: 1s;

}
.phoneNumber{
    text-align: center;
    display: flex;
    justify-content: center;
    /* margin-top: 300px; */
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
}
.swipe{
    height: 300px;
    background: #0E4626;

}
.settingscontainer{
    position: absolute;
width: 50px;
height: 40px;
bottom: 40px; /* set the distance from the bottom of the container */
right: 0px; 

background: rgba(255, 255, 255, 0.1);
display: flex;
align-items: center;
justify-content: center;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
}
.settingsicon{
    /* position: absolute;
left: 25%;
right: 25%;
top: 25%;
bottom: 25%; */

}




.arrow {
    opacity: 0;
    position: absolute;
        left:49%;
        top: 80%;
    transform-origin: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
}

.arrowFirst {
    animation: arrow-movement 2s ease-in-out infinite;
}
.arrowSecond {
    animation: arrow-movement 2s 1s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
    background: #fff;
    content: '';
    display: block;
    height: 3px; 
    position: absolute;
        top: 0;
        left: 0;
    width: 30px;
}

.arrow:before {
    transform: rotate(-45deg) translateX(-30%);
    transform-origin: top left;
}

.arrow:after {
    transform: rotate(45deg) translateX(30%);
    transform-origin: top right;
}


@keyframes arrow-movement {
    0% { 
        opacity: 0;
        top: 85%;
    }
    70% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
    }
}

