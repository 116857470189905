/* .container{
    width:100%;
    height: 100vh;
    text-align: center;
    padding-top: 20px;
    background: url('../../assets/thankyou.png') no-repeat center center fixed;
    background-size: cover;
}
.titleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #FFFFFF;

}
.subTitleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    color: #FFFFFF;
}
.logo{
    margin-bottom: 10px;
}
.linkToHome{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #0E4626;
    margin-top: 30px;

}
.title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 10px;
}
.rightwrapper{
    width: 340px;
  }
.rightwrapperContainer{
    margin-top: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
} 
  .label{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160.52%;
      color: #FFFFFF;
    
  }
  .formRow{
    display: flex;
    justify-content:space-between;
    margin-top: 18px;
}
.rightBox{
    width:150px;
    text-align: left;
}
.price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 160.52%;
    color: #FFFFFF;

}
 */


 .mycomponent {
    background-color: #0E4626;
    width: 100%;
    height: 100vh;
    /* Add any other styles you want here */
}

.centerbox {
    width: 48.125rem;
    height: 34.625rem;
    background: #FFFFFF;
    border-radius: 0.3125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
}

.rightbox {
    width: 29.6875rem;
    height: 34.625rem;
    background-color: #F5F5F5;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rightboxtitle {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    /* convert 30px to rem */
    color: #174F00;
    margin: 3rem 1.25rem .7rem 1.25rem;
}

.right_box__form {
    display: flex;
    flex-direction: column;
    margin: 2.25rem 1.25rem 1.25rem 1.25rem;
    align-items: center;
}

.right_box__form>*+* {
    margin-top: 1.8125rem;
    /* convert 58px to rem */
}


.username_input {
    width: 25rem;
    margin-bottom: 1.5rem !important;
}

.password_input {
    width: 25rem;
    margin-bottom: 1.5rem;
}

.right_box__login_btn {
    margin-top: 3.5rem;
    /* convert 56px to rem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    /* convert 10px to rem */
    padding: 1.25rem 4.375rem;
    /* convert 10px and 70px to rem */
    width: 11.625rem;
    /* convert 186px to rem */
    height: 2.3125rem;
    /* convert 37px to rem */
    background-color: #0E4626;
    border-radius: 0.3125rem;


    text-transform: uppercase;

    letter-spacing: 0.1rem;
    cursor: pointer;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9375rem;
    /* convert 15px to rem */
    line-height: 1.0625rem;
    /* convert 17px to rem */
    color: #FFFFFF;
    white-space: nowrap;
}

.logo {
    width: 9.375rem;
    /* convert 150px to rem */
    height: 9.625rem;
    /* convert 154px to rem */


}

.leftBox {
    width: 18.75rem;
    /* 750px / 16 = 46.875rem */
    height: 34.625rem;
    /* 874px / 16 = 54.625rem */
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    align-items: center;
    background-image: url('../../assets/configured.png');
}

.title {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-top: 20px;

    color: #FFFFFF;
}

.rightBox__error {
    color: red;
    margin-bottom: 1rem;
}











.container{
  
    text-align: center;

    background: #0E4626;
}
.titleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
   

}
.subTitleContainer h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
   
}
.logo{
    margin-bottom: 10px;
  
}
.linkToHome{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #0E4626;
    margin-top: 30px;

}
.title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
   
    text-align: center;
    margin-top: 10px;
}
.rightwrapper{
    width: 340px;
  }
.rightwrapperContainer{
    margin-bottom: 30px;
    padding-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
} 
  .label{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1,44rem;
    
    color: #000000;
    
  }
  .formRow{
    display: flex;
    justify-content:space-between;
    margin-top: 18px;
}
.rightBox{
    width:150px;
    text-align: left;
}
.price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 160.52%;
    

}

.buttoncontainer{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-top: 2.5rem;
}
.homebutton{
    width: 9.81rem;
    height:2.31rem;
   
    background: #0E4626 !important;
    border-radius: 5px;
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: .94rem;
line-height: 1.06rem;

margin-top: 1.1rem;
color: #FFFFFF;

}
.changebutton{
    width: 9.81rem;
    height:2.31rem;
    background: #FFFFFF;
    border-radius: 5px;

    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: .94rem;
line-height: 1.06rem;


color: #0E4626;

   
}

.thakyou_message{
 

font-family: 'Georgia';
font-style: normal;
font-weight: 400;
font-size: 1.8rem;
line-height: 2.13rem;
/* identical to box height */
margin-top: 1rem;

color: #0E4626;

}

.thakyou_message_sub{
    font-family: 'Georgia';
font-style: normal;
font-weight: 400;
font-size: 1.25rem;
line-height:.8rem;
/* identical to box height */

text-align: center;

color: #0E4626;

}