.modalContainer{
    background: #0E4626;
    height: 100%;
}
.title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 60px;
}
.rightwrapper{
    width: 280px;
  }
.rightwrapperContainer{
    margin-top: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
} 
  .label{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160.52%;
      color: #FFFFFF;
    
  }
  .formRow{
    display: flex;
    justify-content:space-between;
    margin-top: 18px;
}
.rightBox{
    width:150px;
    text-align: right;
}
.price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 160.52%;
    color: #FFFFFF;

}
  button{
  padding: 10px 20px;
  background: #FFFFFF;
  border-radius: 10px
  }
.formContainer{
    margin-top: 55px;
    display: flex;
    justify-content: center;
}  
.form{
    width:300px
}
.formInline{
    width: 100%;
    display: flex;
    flex-direction: row;
   
}
.payButtonWrapp{
    margin-left: 25px;
}
.payRow{
    display: flex;
    justify-content:center;
    margin-top: 12px;
}
.dialogContent{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    
}
.dialogTitle{
    font-family: 'Roboto';
font-style: normal;
font-size: 33px;
line-height: 34px;
color: #0E4626;

}
.dialogContent p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #0E4626;
    
}
.dialogNumber{
    color: #257fe6;
    font-size: 25px;
}
.spinImage{
animation-name: spin;
animation-duration: 1s;
animation-iteration-count: infinite;
animation-timing-function: linear;
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  .reqButton{
    padding: 10px 20px;
    background: #FFFFFF;
    border-radius: 10px
    }
.noslotbutton{
    width: 100px;
    height: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;


background: #0E4626 !important;
border-radius: 5px;
}